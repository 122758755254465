/* eslint-disable react/require-default-props */
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
// core components

import { hexToRgb, blackColor } from '../../assets/jss/material-dashboard-react';

const cardAvatarStyle = {
  cardAvatar: {
    '&$cardAvatarProfile img': {
      width: '100%',
      height: 'auto',
    },
  },
  cardAvatarProfile: {
    maxWidth: '191px',
    maxHeight: '275px',
    // margin: '-40px auto 0',
    'background-color': '#e0e0e0',
    borderRadius: '5%',
    overflow: 'hidden',
    padding: '0',
    // boxShadow:
    //   '0 16px 38px -12px rgba(0, 0, 0, 0.12), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    // '&$cardAvatarPlain': {
    //   marginTop: '0',
    // },
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.5)',
      transition: 'box-shadow 500ms',
      '&$cardAvatarPlain': {
        marginTop: '0',
      },
  },
  cardAvatarPlain: {},
};
function CardAvatar({ ...props }) {
  const {
    classes, children, className, plain, profile, ...rest
  } = props;
  const cardAvatarClasses = classNames({
    [classes.cardAvatar]: true,
    [classes.cardAvatarProfile]: profile,
    [classes.cardAvatarPlain]: plain,
    [className]: className !== undefined,
  });
  return (
    <div className={cardAvatarClasses} {...rest}>
      {children}
    </div>
  );
}

CardAvatar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  profile: PropTypes.bool,
  plain: PropTypes.bool,
};

export default withStyles(cardAvatarStyle)(CardAvatar);
