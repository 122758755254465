import React from "react"
import Img from "gatsby-image"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

// core components
import Card from "./card/Card"
import CardAvatar from "./card/CardAvatar"
import CardBody from "./card/CardBody"

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "300",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  nodisplay: {
    display: "none",
  },

  alignVerticalHorizontal: {
    "@media (max-width: 1280px)": {
      display: "flex",
      "align-items": "center",
      "justify-content": "center",
    },
  },
}

function Avatar(props) {
  const { classes } = props
  return (
    <div>
      <Card profile>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} lg={6}>
            <div className={classes.alignVerticalHorizontal}>
              <CardAvatar profile>
                <Img
                  fixed={props.ImgData.childImageSharp.fixed}
                  alt="Lucas de Melo Silva"
                />
              </CardAvatar>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <CardBody profile>
              <Typography color="secondary" gutterBottom>
                Assistant Professor
              </Typography>
              <Typography color="secondary" gutterBottom>
                Computer Science Department
              </Typography>
              <Typography color="secondary" gutterBottom>
                University of Iowa
              </Typography>
              <Typography color="secondary" gutterBottom>
                <em>lucas-silva [at] uiowa.edu</em>
              </Typography>
            </CardBody>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default withStyles(styles)(Avatar)
